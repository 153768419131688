import React, { useEffect, useState } from "react";
import { BiBriefcase } from "react-icons/bi";
import LOGO from "../../assets/images/logogc.png";
import "../../App.css";
import Country from "../../assets/countries.json";
import { useTranslation } from "react-i18next";

import {
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  UserOutlined,
  LogoutOutlined,
  TeamOutlined,
  BarsOutlined,
  DownOutlined,
  DashboardOutlined,
} from "@ant-design/icons";
import {
  Breadcrumb,
  Button,
  Input,
  Layout,
  Menu,
  Modal,
  Form,
  Select,
  Space,
  Table,
  Dropdown,
} from "antd";
import { Link, useNavigate } from "react-router-dom";
import Logout from "../../component/logout/Logout";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import DeleteModal from "../../component/model/Model";
import { API_URL } from "../../helper/Helper";
import ErrorToast from "../../component/error/ErrorToast";

const { Header, Sider, Content } = Layout;
const { Option } = Select;

const Club = () => {
  const { t } = useTranslation();
  const [collapsed, setCollapsed] = useState(false);
  const [search, setSearch] = useState("");
  const [updateId,SetUpdateId]=useState()
  const [clubInput, setClubInput] = useState({
    email: "",
    country: "",
    password: "",
    language: [],
  });

  const [updateAdmin,setupdateAdmin]=useState({
    email: "",
    country: "",
    password: "",
    language: [],
  })

  const [logOutModal, setLogOutModal] = useState(false);
  const REGEX =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  const onClickLogOutModal = () => {
    setLogOutModal(true);
  };

  const [language, setlanguage] = useState(["en", "fi"]);
  const [country, setCountry] = useState(Country);
  const [loading, setLoading] = useState(false);

  const [data, setData] = useState([]);
  const [isCreateClubModal, setIsCreateClubModel] = useState(false);
  const [isEditCreateModal, setIsEditCreateModel] = useState(false);
 
  const [token, setToken] = useState(
    localStorage.getItem("token") ? localStorage.getItem("token") : null
  );
  const [visible, setVisible] = useState(false);
  const [id, setId] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    if (!token) {
      navigate("/login");
    }
  }, []);

  useEffect(() => {
    getCountyAdmin();
  }, []);

  const onClickClubName = (cid) => {
    navigate(`/clubmember/${cid}`);
  };

  const getCountyAdmin = () => {
    setLoading(true);
    fetch(`${API_URL}/api/countryadmin/getCountryAdmin`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      method: "GET",
    })
      .then((res) => {
        return res.json();
      })
      .then((e) => {
        setData(
          e.data.map((item, i) => {
            return {
              id: item._id,
              s_no: i + 1,
              admin: item.email,
              country: item.country,
              filteradmin: item.email,
              language:item.language,
              password:item.password,
            };
          })
        );
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleUpdateBtn = (record) => {
    var language = record.language.split(",")

    setIsEditCreateModel(true);
    SetUpdateId(record.id)
    setupdateAdmin(updateAdmin => ({
      ...updateAdmin,
      email: record.admin,
      country:  record.country,
      password:  record.password,
      language: language,
    }))

  };


  const onClickDelteBtn = (record) => {
    setId(record.id);
    setVisible(true);
  };

  const onClickDeleteBtn = () => {
    fetch(`${API_URL}/api/countryadmin/deleteCountryAdmin/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      method: "DELETE",
    })
      .then((res) => {
        return res.json();
      })
      .then((e) => {
        if (e.status === 200) {
          getCountyAdmin();
          toast.success(e.message, {
            position: "top-right",
            autoClose: 1000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          setVisible(false);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const columns = [
    {
      title: t("Sr No."),
      dataIndex: "s_no",
      sorter: {
        compare: (a, b) => a.s_no - b.s_no,
        multiple: 5,
      },
    },
    {
      title: t("admin"),
      dataIndex: "admin",
      sorter: {
        compare: (c, d) => (c.cname < d.cname ? 1 : -1),
        multiple: 5,
      },
    },
    {
      title: t("country"),
      dataIndex: "country",
      sorter: {
        compare: (c, d) => (c.cname < d.cname ? 1 : -1),
        multiple: 5,
      },
    },
    {
      title: t("Operation"),
      fixed: "right",
      width: 100,
      dataIndex: "action",
      render: (index, record) => (
        <Dropdown
          // onMenuClick={(e) => handleUpdate(record, e)}
          overlay={
            <Menu
              // onClick={(e) => handleMenuClick(record, e)}
              items={[
                {
                  key: "1",
                  label: (
                    <Button
                      style={{ border: "none" }}
                      onClick={() => handleUpdateBtn(record)}
                    >
                      {t('Update')}
                    </Button>
                  ),
                },
                {
                  key: "2",
                  label: (
                    <Button
                      style={{ border: "none" }}
                      onClick={() => onClickDelteBtn(record)}
                    >
                      {t("delete")}
                    </Button>
                  ),
                },
              ]}
            />
          }
        >
          <a onClick={(e) => e.preventDefault()}>
            <Space>
              <Button style={{ border: "none" }}>
                <BarsOutlined style={{ marginRight: 2 }} />
                <DownOutlined />
              </Button>
            </Space>
          </a>
        </Dropdown>
      ),
    },
  ];

  const AddshowModal = () => {
    setIsCreateClubModel(true);
  };

  const searchChangehandler = (events) => {
    setSearch(events.target.value);
  };

  const createClubModalOk = () => {
    setIsCreateClubModel(false);
  };

  const createClubModalCancel = () => {
    setIsCreateClubModel(false);
  };

  const crateClubNameModalCancel = () => {
    setIsCreateClubModel(false);
  };
  const UpdateClubNameModalCancel = () => {
    setIsEditCreateModel(false);
  };
//  create
  const onChangeEmail = (e) => {
    setClubInput({ ...clubInput, email: e.target.value });
  };

  const handleChangeLanguage = (e) => {
    setClubInput({ ...clubInput, language: e });
  };

  const onChangePassword = (e) => {
    setClubInput({ ...clubInput, password: e.target.value });
  };

  const onChangeCountry = (e) => {
    setClubInput({ ...clubInput, country: e });
  };
  //  update
  const onChangeEmail2 = (e) => {
    setupdateAdmin({ ...updateAdmin, email: e.target.value });
  };

  const handleChangeLanguage2 = (e) => {
    setupdateAdmin({ ...updateAdmin, language: e });
  };

  const onChangePassword2 = (e) => {
    setupdateAdmin({ ...updateAdmin, password: e.target.value });
  };

  const onChangeCountry2 = (e) => {
    setupdateAdmin({ ...updateAdmin, country: e });
  };

  const onClickCreateClubSubmitBtn = async () => {
    if (!clubInput.email.trim().match(REGEX)) {
      toast.error(t("enter valid email"), {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else if (clubInput.password.trim().length === 0) {
      toast.error(t("enter valid password"), {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else if (clubInput.country.trim().length === 0) {
      toast.error(t("enter valid country"), {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
     } else if (clubInput.language.length === 0) {
        toast.error(t("enter valid Language"), {
          position: "top-right",
          autoClose: 1000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
    }
    else {
      var urlencoded = new URLSearchParams();
      urlencoded.append("email", clubInput.email);
      urlencoded.append("password", clubInput.password);
      urlencoded.append("language", clubInput.language);
      urlencoded.append("country", clubInput.country);
      await fetch(`${API_URL}/api/countryadmin/AddCountryAdmin`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        method: "POST",
        body: urlencoded,
      })
        .then((res) => {
          return res.json();
        })
        .then((e) => {
          if (e.status === 200) {
            getCountyAdmin();
            setIsCreateClubModel(false);
            toast.success(e.message, {
              position: "top-right",
              autoClose: 1000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
            setClubInput({
              email: "",
              password: "",
              country: "",
            });
          }
          else{
            toast.error(e.message, {
              position: "top-right",
              autoClose: 1000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          }
        })
        .catch((e) => {
          console.log(e);
        });
    }
  };
  const onClickUpdateAdminSubmitBtn = async () => {
    if (!updateAdmin.email.trim().match(REGEX)) {
      toast.error(t("enter valid email"), {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else if (updateAdmin.password.trim().length === 0) {
      toast.error(t("enter valid password"), {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else if (updateAdmin.country.trim().length === 0) {
      toast.error(t("enter valid country"), {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
     }else if (updateAdmin.language.length === 0) {
        toast.error(t("enter valid Language"), {
          position: "top-right",
          autoClose: 1000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
    }
    else {
      var urlencoded = new URLSearchParams();
      urlencoded.append("adminId",updateId);
      urlencoded.append("email", updateAdmin.email);
      urlencoded.append("password", updateAdmin.password);
      urlencoded.append("language", updateAdmin.language);
      urlencoded.append("country", updateAdmin.country);
      await fetch(`${API_URL}/api/countryadmin/EditCountryAdmin`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        method: "PUT",
        body: urlencoded,
      })
        .then((res) => {
          return res.json();
        })
        .then((e) => {
          if (e.status === 200) {
            getCountyAdmin();
            setIsEditCreateModel(false);
            toast.success(e.message, {
              position: "top-right",
              autoClose: 1000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
            setupdateAdmin({
              email: "",
              password: "",
              country: "",
            });
          }
          else{
            toast.error(e.message, {
              position: "top-right",
              autoClose: 1000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          }
        })
        .catch((e) => {
          console.log(e);
        });
    }
  };



  const filterdata = data.filter((item) => {
    return (
      item.filteradmin.toLocaleLowerCase().indexOf(search.toLowerCase()) !== -1
    );
  });

  return (
    <Layout style={{ height: "100vh" }}>
      <Sider
        trigger={null}
        collapsible
        collapsed={collapsed}
        theme="light"
        style={{ position: "sticky", height: "100vh", top: "0" }}
      >
        <div className="logo">
          {/* <p className="logoText">GC</p> */}
          <img src={LOGO} height={30} width={30} className="logoText" />
        </div>
        <Menu
          style={{ padding: "24px 0px" }}
          mode="inline"
          defaultSelectedKeys={["6"]}
          items={[
            {
              key: "1",
              icon: <DashboardOutlined />,
              label: <Link to="/dashboard">{t("Dashboard")}</Link>,
            },
            {
              key: "6",
              icon: <UserOutlined />,
              label: <Link to="/countryadmin">{t("Country Admin")}</Link>,
            },
            {
              key: "2",
              icon: <TeamOutlined />,
              label: <Link to="/club">{t("Club")}</Link>,
            },
            {
              key: "3",
              icon: <UserOutlined />,
              label: <Link to="/member">{t("Member")}</Link>,
            },
            {
              key: "5",
              icon: <BiBriefcase style={{ fontSize: "18px" }} />,
              label: <Link to="/work">{t("Work")}</Link>,
            },
            {
              key: "4",
              icon: <LogoutOutlined />,
              label: (
                <a href="javascript:void(0)" onClick={onClickLogOutModal}>
                  {t("Logout")}
                </a>
              ),
            },
          ]}
        />
      </Sider>
      <Layout className="site-layout">
        <Header
          className="site-layout-background"
          style={{
            padding: "0px 20px",
          }}
        >
          {React.createElement(
            collapsed ? MenuUnfoldOutlined : MenuFoldOutlined,
            {
              className: "trigger",
              onClick: () => setCollapsed(!collapsed),
            }
          )}
        </Header>
        <Content className="main-content">
          <Breadcrumb
            style={{
              margin: "16px 0",
            }}
          >
            <Breadcrumb.Item>
              <TeamOutlined />
              &nbsp; {t("Club")}
            </Breadcrumb.Item>
          </Breadcrumb>
          <div
            className="site-layout-background"
            style={{
              padding: 24,
              // minHeight: 360,
            }}
          >
            <span>
              <b style={{ fontSize: "15px", marginBottom: "20px" }}>
                {t("Search")}
              </b>{" "}
              ({t("Admin name")}):
            </span>
            <div
              style={{
                display: "flex",
                marginBottom: "20px",
                marginTop: "10px",
              }}
            >
              <Input
                onChange={searchChangehandler}
                placeholder={t("Search Club")}
                value={search}
                style={{ marginRight: "10px" }}
              />
              <Button
                type="primary"
                onClick={AddshowModal}
                style={{ float: "right" }}
              >
                {t("Add Admin")}
              </Button>
            </div>
            <Table
              tableLayout="auto"
              scroll="unset"
              columns={columns}
              simple
              loading={loading}
              bordered
              dataSource={filterdata}
              pagination={{
                ...filterdata.pagination,
                showTotal: (total) => `Total ${total} Items`,
              }}
            ></Table>
          </div>
        </Content>
      </Layout>
      <Modal
        title={t("Add Admin")}
        visible={isCreateClubModal}
        onOk={createClubModalOk}
        onCancel={createClubModalCancel}
        footer={null}
      >
        <Form
          labelCol={{ span: 6 }}
          //   wrapperCol={{ span: 18 }}
        >
          <Form.Item label={t("Email")} style={{ width: "100%" }}>
            <Input value={clubInput.email} onChange={onChangeEmail} />
          </Form.Item>

          <Form.Item label={t("password")} style={{ width: "100%" }}>
            <Input value={clubInput.password} onChange={onChangePassword} />
          </Form.Item>

          <Form.Item label={t("country")}>
            <Select
              value={clubInput.country}
              showSearch
              style={{
                width: "100%",
              }}
              onChange={onChangeCountry}
            >
              {country.map((item, index) => {
                return (
                  <>
                    <Option value={item.name} key={index}>
                      {item.name}
                    </Option>
                  </>
                );
              })}
            </Select>
          </Form.Item>

          <Form.Item label={t("Language")}>
            <Select
              value={clubInput.language}
              mode="multiple"
              showArrow
              allowClear
              style={{
                width: "100%",
              }}
              onChange={handleChangeLanguage}
              filterOption={(input, option) => {
                return (
                  option.children.toLowerCase().indexOf(input.toLowerCase()) !==
                  -1
                );
              }}
            >
              {language.map((item, index) => {
                return (
                  <>
                    <Option value={item} key={index} children={item}>
                      {item}
                    </Option>
                  </>
                );
              })}
            </Select>
          </Form.Item>

          <Form.Item
            // wrapperCol={{ offset: 8, span: 16 }}
            style={{
              display: "flex",
              justifyContent: "end",
              marginBottom: "0",
              marginTop: "20px",
            }}
          >
            <Button type="default" onClick={crateClubNameModalCancel}>
              {t("Cancel")}
            </Button>
            <Button
              type="primary"
              htmlType="submit"
              style={{
                marginLeft: "10px",
              }}
              onClick={onClickCreateClubSubmitBtn}
            >
              {t("Submit")}
            </Button>
          </Form.Item>
        </Form>
      </Modal>

      {/* update Model */}
      <Modal
        title={t("Update Admin")}
        visible={isEditCreateModal}
        onOk={() => setIsEditCreateModel(false)}
        onCancel={() => setIsEditCreateModel(false)}
        footer={null}
      >
        <Form
          labelCol={{ span: 6 }}
          //   wrapperCol={{ span: 18 }}
        >
          <Form.Item label={t("Email")} style={{ width: "100%" }}>
            <Input value={updateAdmin.email} onChange={onChangeEmail2} />
          </Form.Item>

          <Form.Item label={t("password")} style={{ width: "100%" }}>
            <Input value={updateAdmin.password} onChange={onChangePassword2} />
          </Form.Item>

          <Form.Item label={t("country")}>
            <Select
              value={updateAdmin.country}
              showSearch
              style={{
                width: "100%",
              }}
              onChange={onChangeCountry2}
            >
              {country.map((item, index) => {
                return (
                  <>
                    <Option value={item.name} key={index}>
                      {item.name}
                    </Option>
                  </>
                );
              })}
            </Select>
          </Form.Item>

          <Form.Item label={t("Language")}>
            <Select
              value={updateAdmin.language}
              mode="multiple"
              showArrow
              allowClear
              style={{
                width: "100%",
              }}
              onChange={handleChangeLanguage2}
              filterOption={(input, option) => {
                return (
                  option.children.toLowerCase().indexOf(input.toLowerCase()) !==
                  -1
                );
              }}
            >
              {language.map((item, index) => {
                return (
                  <>
                    <Option value={item} key={index} children={item}>
                      {item}
                    </Option>
                  </>
                );
              })}
            </Select>
          </Form.Item>

          <Form.Item
            // wrapperCol={{ offset: 8, span: 16 }}
            style={{
              display: "flex",
              justifyContent: "end",
              marginBottom: "0",
              marginTop: "20px",
            }}
          >
            <Button type="default" onClick={UpdateClubNameModalCancel}>
              {t("Cancel")}
            </Button>
            <Button
              type="primary"
              htmlType="submit"
              style={{
                marginLeft: "10px",
              }}
              onClick={onClickUpdateAdminSubmitBtn}
            >
              {t("Submit")}
            </Button>
          </Form.Item>
        </Form>
      </Modal>

      {/* component */}
      <Logout
        visibleLogOutModal={logOutModal}
        onClickLogOutModelCancel={() => setLogOutModal(false)}
      />
      <DeleteModal
        title={t("Country Admin")}
        text={t("Are you sure you want to delete Country Admin ?")}
        visibleModal={visible}
        onClickDeleteBtn={onClickDeleteBtn}
        modalCancel={() => setVisible(false)}
        modalOk={() => setVisible(false)}
      />
    </Layout>
  );
};

export default Club;
