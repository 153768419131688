import React from "react";
import "./App.css";
import { BrowserRouter } from "react-router-dom";
import "antd/dist/antd.min.css";
import "./index.css";
import AdminLayout from "./layout/AdminLayout";
import { I18nextProvider } from "react-i18next";
import i18n from "./i18n/i18n";

function App() {
  return (
    <>
      <I18nextProvider i18n={i18n}>
        <BrowserRouter>
          <AdminLayout />
        </BrowserRouter>
      </I18nextProvider>
    </>
  );
}

export default App;
