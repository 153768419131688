import {
  generateVideoThumbnails,
  importFileandPreview,
} from "@rajesh896/video-thumbnails-generator";
import { Bars } from "react-loader-spinner";

import React, { useEffect, useRef, useState } from "react";
import LOGO from "../../assets/images/logogc.png";
import { BiBriefcase } from "react-icons/bi";
import "../../App.css";
import { useTranslation } from "react-i18next";

import {
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  UserOutlined,
  LogoutOutlined,
  TeamOutlined,
  BarsOutlined,
  DownOutlined,
  DashboardOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import {
  Breadcrumb,
  Button,
  Input,
  Layout,
  Menu,
  Modal,
  Form,
  Table,
  Dropdown,
  Space,
  Select,
} from "antd";
import Country from "../../assets/countries.json";
import { Link, useNavigate } from "react-router-dom";
import Logout from "../../component/logout/Logout";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import DeleteModal from "../../component/model/Model";
import ReactHtmlParser from "react-html-parser";
import Editor from "ckeditor5-custom-build/build/ckeditor";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import { API_URL } from "../../helper/Helper";
const { Header, Sider, Content } = Layout;

// const { Option, OptGroup } = Select;

const Work = () => {
  const [country, setCountry] = useState(Country);
  const { Option } = Select;
  const { t } = useTranslation();
  const [addLanguageButton,setAddLanguageButton]=useState(false)
  const [collapsed, setCollapsed] = useState(false);
  const [search, setSearch] = useState("");
  const [workVideo, setWorkVideo] = useState(null);
  const [CreateWorkObject, setCreateWorkObject] = useState([
    {
      language: "",
      name: "",
      htmlContant: "",
    },
  ]);
  const [logOutModal, setLogOutModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [token, setToken] = useState(
    localStorage.getItem("token") ? localStorage.getItem("token") : null
  );
  const [isCreateWorkModal, setIsWorkModal] = useState(false);
  const [data, setData] = useState([]);
  const [visibelDeleteModal, setVisibleDaleteModal] = useState(false);
  const [deleteWorkId, setDeleteWorkId] = useState("");
  const [isUpdateWork, setIsUpdateWork] = useState(false);
  const [editWorkId, setEditWorkId] = useState("");
  const [editWorkName, setEditWorkName] = useState("");
  const [editWorkVideo, setEditWorkVideo] = useState("");
  const [editWorkHtml, setEditWorkHtml] = useState("");
  const [UpdateWorkObject, setUpdateWorkObject] = useState([
  ]);
  const [clubInput, SetClubInput] = useState();
  const [editVideo, setEditVideo] = useState(null);
  const [updateVideoPreview, setUpdateVideoPreview] = useState("");
  const [viewContentModal, setViewContentModal] = useState(false);
  const [viewHtmlContent, setViewHtmlContent] = useState("");
  const updateVideoRef = useRef();
  const videoRef = useRef();
  const [videoPreview, setVidePreview] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [CreateLanguage, setCreateLanguage] = useState(["en","fr"]);
  const navigate = useNavigate();
  useEffect(() => {
    if (!token) {
      navigate("/login");
    }
  }, []);

  useEffect(() => {
    getWorkData();
  }, []);
  useEffect(() => {
    if (!workVideo) {
      setVidePreview(undefined);
      return;
    }
    const object = window.URL.createObjectURL(workVideo);
    setVidePreview(object);
    return () => window.URL.revokeObjectURL(object);
  }, [workVideo]);

  useEffect(() => {
    if (!editVideo) {
      setUpdateVideoPreview(undefined);
      return;
    }
    const object = window.URL.createObjectURL(editVideo);
    setUpdateVideoPreview(object);
    return () => window.URL.revokeObjectURL(object);
  }, [editVideo]);

  const columns = [
    {
      title: t("S.no"),
      dataIndex: "s_no",
      sorter: {
        compare: (a, b) => a.s_no - b.s_no,
        multiple: 5,
      },
    },
    {
      title: t("Work"),
      dataIndex: "wname",
      sorter: {
        compare: (c, d) => (c.cname < d.cname ? 1 : -1),
        multiple: 5,
      },
    },
    {
      title: t("Work Video"),
      dataIndex: "wvideo",
    },
    {
      title: t("Html Content"),
      dataIndex: "whtml",
    },
    {
      title: t("Operation"),
      fixed: "right",
      width: 100,
      dataIndex: "action",
      render: (index, record) => (
        <Dropdown
          // onMenuClick={(e) => handleUpdate(record, e)}
          overlay={
            <Menu
              // onClick={(e) => handleMenuClick(record, e)}
              items={[
                {
                  key: "1",
                  label: (
                    <Button
                      style={{ border: "none" }}
                      onClick={() => onClickUpdateWork(record)}
                    >
                      {t("Update")}
                    </Button>
                  ),
                },
                {
                  key: "2",
                  label: (
                    <Button
                      style={{ border: "none" }}
                      onClick={() => onClickDeleteWork(record)}
                    >
                      {t("Delete")}
                    </Button>
                  ),
                },
              ]}
            />
          }
        >
          <a onClick={(e) => e.preventDefault()}>
            <Space>
              <Button style={{ border: "none" }}>
                <BarsOutlined style={{ marginRight: 2 }} />
                <DownOutlined />
              </Button>
            </Space>
          </a>
        </Dropdown>
      ),
    },
  ];

  const onClickViewContent = (content) => {
    setViewHtmlContent(content);
    setViewContentModal(true);
  };
  const languageAddHandler = () => {
    const arry = [];
    const mainArry = [];
    const updatedWorkObject = [...UpdateWorkObject]; 
    for (let i = 0; i < UpdateWorkObject.length; i++) {
      arry.push(UpdateWorkObject[i].language);
    }
    for (let i = 0; i < CreateLanguage.length; i++) {
      if (!arry.includes(CreateLanguage[i])) {
        mainArry.push(CreateLanguage[i]);
      }
    }
    for (let i = 0; i < mainArry.length; i++) {
      updatedWorkObject.push({
        htmlContant: "",
        language: mainArry[i],
        name:"",
        workhtml: ""
      });
    }
    setUpdateWorkObject(updatedWorkObject);
    setAddLanguageButton(false)
  };
  useEffect(() => {
  }, [UpdateWorkObject]); 
  //  --------------------------
  const handleInputChange = (event, index, data) => {
    if (event.target) {
      const { name, value } = event.target;
      const updatedArray = [...CreateWorkObject];
      updatedArray[index] = {
        ...updatedArray[index],
        [name]: value,
        language: CreateLanguage[index],
      };
      setCreateWorkObject(updatedArray);
    } else {
      const updatedArray = [...CreateWorkObject];
      updatedArray[index] = { ...updatedArray[index], htmlContant: data };
      setCreateWorkObject(updatedArray);
    }
  };
  //  -------------------------
  //  -------------------------
  const handleUpdateInputChange = (event, index, data) => {
    var updatedArray = UpdateWorkObject;
    if (updatedArray[index]["htmlContant"] === data) {
      if (event.target) {
        const { name, value } = event.target;
        updatedArray[index]["name"] = value;
      } else {
        updatedArray[index]["htmlContant"] = data;
      }
      setUpdateWorkObject([...updatedArray]);
    }
    else{
      if (event.target) {
        const { name, value } = event.target;
        updatedArray[index]["name"] = value;
      } else {
        updatedArray[index]["htmlContant"] = data;
      }
      setUpdateWorkObject([...updatedArray]);
    }
  };

  const getWorkData = async () => {
    setLoading(true);
    await fetch(`${API_URL}/api/work/getAllAdminWorks`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      method: "GET",
    })
      .then((res) => {
        return res.json();
      })
      .then((item) => {
        // setAllDataofCreateWork(item)
        setData(
          item.data.map((e, i) => {
            return {
              id: e._id,
              s_no: i + 1,
              wname: e.name,
              workdata: e.work,
              wvideo: (
                <>
                  {e.video ? (
                    <video height={100} width={200} controls key={e.video}>
                      <source src={`${API_URL}/${e.video}`} type="video/mp4" />
                    </video>
                  ) : (
                    t("No Video Found")
                  )}
                </>
              ),
              video: e.video,
              html: e.htmlContant,
              whtml: (
                <>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    {/* {ReactHtmlParser(e.htmlContant)} */}
                    <Button onClick={() => onClickViewContent(e.htmlContant)}>
                      {t("View Content")}
                    </Button>
                  </div>
                </>
              ),
            };
          })
        );
        setLoading(false);
      });
  };

  const filterData = data.filter((item) => {
    const name = item.wname;
    if (name) {
      return name.toLocaleLowerCase().indexOf(search.toLowerCase()) !== -1;
    }
    return false;
  });

  const onClickUpdateWork = (record) => {
    var record2 = JSON.parse(record.workdata);
    setUpdateWorkObject([...record2]);
    setIsUpdateWork(true);
    setEditWorkId(record.id);
    setEditWorkName(record2.wname);
    setEditWorkVideo(record2.video);
    setEditWorkHtml(record2.html);
    if(record2.length == CreateLanguage.length){
      setAddLanguageButton(false)
    }else{
      setAddLanguageButton(true)
    }
  };

  const onClickDeleteWork = (record) => {
    setDeleteWorkId(record.id);
    setVisibleDaleteModal(true);
  };

  const onClickLogOutModal = () => {
    setLogOutModal(true);
  };

  const AddshowModal = () => {
    setIsWorkModal(true);
  };

  const searchChangehandler = (events) => {
    setSearch(events.target.value);
  };

  const createClubModalOk = () => {
    setIsWorkModal(false);
  };

  const CountryInput = (event) => {
    SetClubInput(event);
  };

  const createClubModalCancel = () => {
    setWorkVideo("");
    videoRef.current.value = "";
    setVidePreview(null);
    setIsWorkModal(false);
  };

  const onChangeVideoUpload = (e) => {
    setWorkVideo(e.target.files[0]);
  };

  const onClickCancelUpdateWork = () => {
    updateVideoRef.current.value = "";
    setUpdateVideoPreview("");
    setIsUpdateWork(false);
  };

  const handleUpdateWork = async () => {
    if (UpdateWorkObject.name && UpdateWorkObject.name.trim().length === 0) {
      toast.error(t("Please Input Work Name"), {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else if (
      UpdateWorkObject.htmlContant &&
      UpdateWorkObject.htmlContant.trim().length == 0
    ) {
      toast.error(t("Please Input Html Content"), {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else {
      setIsLoading(true);

      var formdata = new FormData();
      formdata.append("work", JSON.stringify(UpdateWorkObject));
      formdata.append("video", editVideo);
      formdata.append("country", "india");

      if (editVideo) {
        await generateVideoThumbnails(editVideo, 0)
          .then((thumbnailArray) => {
            //Usage example:
            var thumbnail = dataURLtoFile(thumbnailArray[0], "thumbnail.jpg");
            formdata.append("videoThumbnail", thumbnail);
          })
          .catch((err) => {
            console.error(err);
          });
      }
      console.log(("editWorkId",editWorkId));
      fetch(`${API_URL}/api/work/EditWork/${editWorkId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        method: "PUT",
        body: formdata,
      })
        .then((res) => {
          return res.json();
        })
        .then((e) => {
          if (e.status === 200) {
            toast.success(e.message, {
              position: "top-right",
              autoClose: 1000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
            setIsUpdateWork(false);
            getWorkData();
            // setUpdateWorkObject([
            //   ...UpdateWorkObject,
            //   { name: "", htmlContant: "" },
            // ]);
            UpdateWorkObject("");

            updateVideoRef.current.value = "";
            setUpdateVideoPreview("");
            // }
          } else {
            toast.error(e.message, {
              position: "top-right",
              autoClose: 1000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          }
          setIsLoading(false);
        })
        .catch((e) => {
          console.log(e);
          setIsLoading(false);
        });
    }
  };

  const onChangeUpdateVideo = (e) => {
    setEditVideo(e.target.files[0]);
  };

  function dataURLtoFile(dataurl, filename) {
    var arr = dataurl.split(","),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, { type: mime });
  }

  //CREATE WORK API
  const onClickSubmitCreateWorkModal = async () => {
    if ( CreateWorkObject[0].name.trim().length === 0) {
      toast.error(t("Please Input Work Name"), {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else if (CreateWorkObject[0].htmlContant.trim().length == 0) {
      toast.error(t("Please Input Html Content"), {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else {
      setIsLoading(true);

      var formdata = new FormData();
      formdata.append("work", JSON.stringify(CreateWorkObject));
      formdata.append("video", workVideo);
      formdata.append("country", clubInput);

      if (workVideo) {
        await generateVideoThumbnails(workVideo, 0)
          .then((thumbnailArray) => {
            //Usage example:
            var thumbnail = dataURLtoFile(thumbnailArray[0], "thumbnail.jpg");
            formdata.append("videoThumbnail", thumbnail);
          })
          .catch((err) => {
            console.error(err);
          });
      }

      fetch(`${API_URL}/api/work/CreateWork`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        method: "POST",
        body: formdata,
      })
        .then((res) => {
          return res.json();
        })
        .then((data) => {
          if (data.status === 200) {
            setIsWorkModal(false);
            getWorkData();
            
            toast.success(data.message, {
              position: "top-right",
              autoClose: 1000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
            console.log('CreateWorkObject11',CreateWorkObject);
            const newCreateWorkObject = {
              language: "",
              name: "",
              htmlContant: "",
            };
            setCreateWorkObject(newCreateWorkObject);
            console.log('CreateWorkObject12',CreateWorkObject);

            setWorkVideo("");
            videoRef.current.value = "";
            setVidePreview("");
          } else {
            toast.error(data.message, {
              position: "top-right",
              autoClose: 1000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          }
          setIsLoading(false);
        })
        .catch((e) => {
          console.log(e);
          setIsLoading(false);
        });
    }
  };
  

  const onClickDeleteButton = () => {
    fetch(`${API_URL}/api/work/DeleteWork/${deleteWorkId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      method: "DELETE",
    })
      .then((res) => {
        return res.json();
      })
      .then((e) => {
        if (e.status === 200) {
          setVisibleDaleteModal(false);
          getWorkData();
          toast.success(e.message, {
            position: "top-right",
            autoClose: 1000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        } else {
          toast.error(e.message, {
            position: "top-right",
            autoClose: 1000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      });
  };

  function uploadAdapter(loader) {
    return {
      upload: () => {
        return new Promise((resolve, reject) => {
          const body = new FormData();
          loader.file.then((file) => {
            body.append("Image", file);
            // let headers = new Headers();
            // headers.append("Origin", "http://localhost:3000");
            fetch(`${API_URL}/api/work/AddImageCkEditor`, {
              method: "POST",
              headers: {
                Authorization: `Bearer ${token}`,
              },
              body: body,
              // mode: "no-cors"
            })
              .then((res) => res.json())
              .then((res) => {
                // console.log("=======>",`${API_URL}/${res.data}`)
                resolve({
                  default: `${API_URL}/${res.data}`,
                });
              })
              .catch((err) => {
                reject(err);
              });
          });
        });
      },
    };
  }

  function uploadPlugin(editor) {
    editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
      let data = uploadAdapter(loader);
      return data;
    };
  }
  const cancleLanguage = (data) => {
    setUpdateWorkObject(
      UpdateWorkObject.filter(function (el) {
        return el.language != data.language;
      })
    );
    setAddLanguageButton(true)
  };
  return (
    <Layout style={{ height: "100vh" }}>
      <Bars
        height="80"
        width="80"
        color="#2e653a"
        ariaLabel="bars-loading"
        wrapperStyle={{
          position: "absolute",
          "z-index": "10000",
          height: "100%",
          width: "100%",
          "padding-top": "25%",
          "padding-left": "50%",
        }}
        wrapperClass=""
        visible={isLoading}
      />
      <Sider
        trigger={null}
        collapsible
        collapsed={collapsed}
        theme="light"
        style={{ position: "sticky", height: "100vh", top: "0" }}
      >
        <div className="logo">
          {/* <p className="logoText">GC</p> */}
          <img src={LOGO} height={30} width={30} className="logoText" />
        </div>
        <Menu
          style={{ padding: "24px 0px" }}
          mode="inline"
          defaultSelectedKeys={["5"]}
          items={[
            {
              key: "1",
              icon: <DashboardOutlined />,
              label: <Link to="/dashboard">{t("Dashboard")}</Link>,
            },
            {
              key: "6",
              icon: <UserOutlined />,
              label: <Link to="/countryadmin">{t("Country Admin")}</Link>,
            },
            {
              key: "2",
              icon: <TeamOutlined />,
              label: <Link to="/club">{t("Club")}</Link>,
            },
            {
              key: "3",
              icon: <UserOutlined />,
              label: <Link to="/member">{t("Member")}</Link>,
            },
            {
              key: "5",
              icon: <BiBriefcase style={{ fontSize: "18px" }} />,
              label: <Link to="/work">{t("Work")}</Link>,
            },
            {
              key: "4",
              icon: <LogoutOutlined />,
              label: (
                <a href="javascript:void(0)" onClick={onClickLogOutModal}>
                  {t("Logout")}
                </a>
              ),
            },
          ]}
        />
      </Sider>
      <Layout className="site-layout">
        <Header
          className="site-layout-background"
          style={{
            padding: "0px 20px",
          }}
        >
          {React.createElement(
            collapsed ? MenuUnfoldOutlined : MenuFoldOutlined,
            {
              className: "trigger",
              onClick: () => setCollapsed(!collapsed),
            }
          )}
        </Header>
        <Content className="main-content">
          <Breadcrumb
            style={{
              margin: "16px 0",
            }}
          >
            <Breadcrumb.Item className="workflow">
              <BiBriefcase style={{ fontSize: "18px" }} />
              &nbsp; {t("Work")}
            </Breadcrumb.Item>
          </Breadcrumb>
          <div
            className="site-layout-background"
            style={{
              padding: 24,
              // minHeight: 360,
            }}
          >
            <span>
              <b style={{ fontSize: "15px", marginBottom: "20px" }}>
                {t("Search")}{" "}
              </b>{" "}
              {t("Work Name:")}
            </span>
            <div
              style={{
                display: "flex",
                marginBottom: "20px",
                marginTop: "10px",
              }}
            >
              <Input
                onChange={searchChangehandler}
                placeholder={t("Search Work")}
                value={search}
                style={{ marginRight: "10px" }}
              />
              <Button
                type="primary"
                onClick={AddshowModal}
                style={{ float: "right" }}
              >
                {t("Create Work")}
              </Button>
            </div>
            <Table
              tableLayout="auto"
              scroll="unset"
              simple
              bordered
              loading={loading}
              columns={columns}
              dataSource={filterData}
              pagination={{
                ...filterData.pagination,
                showTotal: (total) => `Total ${total} Items`,
              }}
            ></Table>
          </div>
        </Content>
      </Layout>
      {/* CREATE WORK */}
      <Modal
        title={t("Create Work")}
        visible={isCreateWorkModal}
        onOk={createClubModalOk}
        onCancel={createClubModalCancel}
        footer={null}
      >
        <Form
          labelCol={{ span: 6 }}
          //   wrapperCol={{ span: 18 }}
        >
          <Form.Item label={t("country")}>
            <Select
              value={clubInput}
              showSearch
              style={{
                width: "100%",
              }}
              onChange={CountryInput}
            >
              {country.map((item, index) => {
                return (
                  <>
                    <Option value={item.name} key={index}>
                      {item.name}
                    </Option>
                  </>
                );
              })}
            </Select>
          </Form.Item>
          {CreateLanguage?.map((data, i) => {
            return (
              <div key={i} style={{ padding: "5px 0px" }}>
                <h2 style={{ fontSize: "18px", fontWeight: "800" }}>
                  {i + 1} . {data}
                </h2>
                <Form.Item label={t("Name")} style={{ width: "100%" }}>
                  <Input
                    value={data.name}
                    name="name"
                    // onChange={(e) => setWorkName(e.target.value)}
                    onChange={(event) => handleInputChange(event, i)}
                  />
                </Form.Item>

                <Form.Item
                  label={t("Html Content")}
                  style={{ width: "100%" }}
                ></Form.Item>
                <CKEditor
                  config={{
                    extraPlugins: [uploadPlugin],
                  }}
                  editor={Editor}
                  data={data.htmlContant}
                  name="htmlContant"
                  style={{ height: "200px" }}
                  onChange={(event, editor) => {
                    const data = editor.getData();
                    // setCreateWorkObject(data);
                    handleInputChange(event, i, data);

                    // setWorkInput({...workInput,html:data})
                  }}
                  // onChange={(event) => handleInputChange(event, i)}
                />
                <hr style={{ margin: "25px 0px" }} />
              </div>
            );
          })}

          <Form.Item label={t("Upload Video")} style={{ width: "100%" }}>
            <div>
              <input
                type="file"
                ref={videoRef}
                onChange={onChangeVideoUpload}
                id="workvideo"
                style={{ display: "none" }}
              />
              <div>
                <button className="btn">
                  <label htmlFor="workvideo" style={{ cursor: "pointer" }}>
                    {"Choose File"}
                  </label>
                </button>
                <label style={{ marginLeft: 5 }}>
                  {workVideo ? workVideo.name : "No file chosen"}
                </label>
              </div>
              {videoPreview && (
                <video
                  width={"100%"}
                  height={100}
                  controls
                  style={{ cursor: "pointer", marginTop: "10px" }}
                  key={videoPreview}
                >
                  <source src={videoPreview} type="video/mp4" />
                </video>
              )}
            </div>
          </Form.Item>

          <Form.Item
            // wrapperCol={{ offset: 8, span: 16 }}
            style={{
              display: "flex",
              justifyContent: "end",
              marginBottom: "0",
              marginTop: "20px",
            }}
          >
            <Button type="default" onClick={createClubModalCancel}>
              {t("Cancel")}
            </Button>
            <Button
              type="primary"
              htmlType="submit"
              style={{
                marginLeft: "10px",
              }}
              onClick={onClickSubmitCreateWorkModal}
            >
              {t("Submit")}
            </Button>
          </Form.Item>
        </Form>
      </Modal>
      {/* UPDATE WORK */}
      {/* updateModel start */}
      <Modal
        title={t("Update Work")}
        visible={isUpdateWork}
        onOk={() => setIsUpdateWork(false)}
        onCancel={onClickCancelUpdateWork}
        footer={null}
      >
       <Form labelCol={{ span: 6 }}>
          {UpdateWorkObject.map((data, i) => {
            return (
              <div key={i} style={{ padding: "5px 0px" }}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    flexDirection: "row",
                    alignItems: "center",
                    marginBottom: "10px",
                  }}
                >
                  <h2 style={{ fontSize: "18px", fontWeight: "800" }}>
                    {i + 1} . {data["language"]}
                  </h2>
                  <div>
                    <svg
                      style={{cursor:"pointer"}}
                      onClick={() => cancleLanguage(data)}
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      class="bi bi-x-lg"
                      viewBox="0 0 16 16"
                    >
                      <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z" />
                    </svg>
                  </div>
                </div>
                <Form.Item label={t("Name")} style={{ width: "100%" }}>
                  <Input
                    value={data.name}
                    name="name"
                    onChange={(event) => handleUpdateInputChange(event, i)}
                  />
                </Form.Item>

                <Form.Item
                  label={t("Html Content")}
                  style={{ width: "100%" }}
                ></Form.Item>
                <CKEditor
                  config={{
                    extraPlugins: [uploadPlugin],
                  }}
                  editor={Editor}
                  data={data.htmlContant}
                  name="htmlContant"
                  style={{ height: "200px" }}
                  onChange={(event, editor) => {
                    const data = editor.getData();
                    handleUpdateInputChange(event, i, data);
                  }}
                />
                <hr style={{ margin: "25px 0px" }} />
              </div>
            );
          })}

{addLanguageButton && <div
            onClick={languageAddHandler}
            style={{
              border: "1.5px solid gray",
              padding: "5px",
              borderRadius: "5px",
              paddingLeft: "15px",
              marginBottom: "20px",
              width: "35%",
              cursor:"pointer"
            }}
          >
            <PlusOutlined /> &nbsp; Add Language
          </div>}

          <Form.Item label={t("Upload Video")} style={{ width: "100%" }}>
            <div>
              <input
                type="file"
                ref={updateVideoRef}
                onChange={onChangeUpdateVideo}
                id="updateworkvideo"
                style={{ display: "none" }}
              />
              <div>
                <button className="btn">
                  <label
                    htmlFor="updateworkvideo"
                    style={{ cursor: "pointer" }}
                  >
                    {"Choose File"}
                  </label>
                </button>
                <label style={{ marginLeft: 5 }}>
                  {editVideo ? editVideo.name : "No file chosen"}
                </label>
              </div>
              {editWorkVideo && !updateVideoPreview && (
                <video
                  width={"100%"}
                  height={100}
                  controls
                  style={{ cursor: "pointer", marginTop: "10px" }}
                  key={updateVideoPreview ? updateVideoPreview : editWorkVideo}
                >
                  <source
                    src={
                      updateVideoPreview
                        ? updateVideoPreview
                        : `${API_URL}/${editWorkVideo}`
                    }
                    type="video/mp4"
                  />
                </video>
              )}

              {updateVideoPreview && !editWorkVideo && (
                <video
                  width={"100%"}
                  height={100}
                  controls
                  style={{ cursor: "pointer", marginTop: "10px" }}
                  key={updateVideoPreview ? updateVideoPreview : editWorkVideo}
                >
                  <source
                    src={
                      updateVideoPreview
                        ? updateVideoPreview
                        : `${API_URL}/${editWorkVideo}`
                    }
                    type="video/mp4"
                  />
                </video>
              )}

              {updateVideoPreview && editWorkVideo && (
                <video
                  width={"100%"}
                  height={100}
                  controls
                  style={{ cursor: "pointer", marginTop: "10px" }}
                  key={updateVideoPreview ? updateVideoPreview : editWorkVideo}
                >
                  <source
                    src={
                      updateVideoPreview
                        ? updateVideoPreview
                        : `${API_URL}/${editWorkVideo}`
                    }
                    type="video/mp4"
                  />
                </video>
              )}
            </div>
          </Form.Item>

        

          <Form.Item
            // wrapperCol={{ offset: 8, span: 16 }}
            style={{
              display: "flex",
              justifyContent: "end",
              marginBottom: "0",
              marginTop: "20px",
            }}
          >
            <Button type="default" onClick={onClickCancelUpdateWork}>
              {t("Cancel")}
            </Button>
            <Button
              type="primary"
              htmlType="submit"
              style={{
                marginLeft: "10px",
              }}
              onClick={handleUpdateWork}
            >
              {t("Submit")}
            </Button>
          </Form.Item>
        </Form>
      </Modal>
      {/* updateModel end */}
      {/* View Html Content in Modal */}
      <Modal
        title={t("View Content")}
        visible={viewContentModal}
        onCancel={() => setViewContentModal(false)}
        onOk={() => setViewContentModal(false)}
        footer={null}
      >
        <Form>
          {ReactHtmlParser(viewHtmlContent)}
          <Form.Item
            // wrapperCol={{ offset: 8, span: 16 }}
            style={{
              display: "flex",
              justifyContent: "end",
              marginBottom: "0",
              marginTop: "20px",
            }}
          >
            <Button type="default" onClick={() => setViewContentModal(false)}>
              {t("Cancel")}
            </Button>
          </Form.Item>
        </Form>
      </Modal>
      {/* <ToastContainer
          position="top-right"
          autoClose={500}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        /> */}
      <Logout
        visibleLogOutModal={logOutModal}
        onClickLogOutModelCancel={() => setLogOutModal(false)}
      />
      <DeleteModal
        title={t("Work")}
        modalCancel={() => setVisibleDaleteModal(false)}
        modalOk={() => setVisibleDaleteModal(false)}
        onClickDeleteBtn={onClickDeleteButton}
        text={t("Are you sure you want to delete Work?")}
        visibleModal={visibelDeleteModal}
      />
    </Layout>
  );
};

export default Work;
