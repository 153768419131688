import { Box, Button, Paper, TextField, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { API_URL } from "../../../helper/Helper";
import { useTranslation } from "react-i18next";

const REGEX =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const Register = () => {
  const { t } = useTranslation();
  const [passwordShow, setPasswordShow] = useState(false);
  const eyeHandler = () => {
    setPasswordShow((prevState) => !prevState);
  };
  const [authInput, setAuthInput] = useState({
    email: "",
    password: "",
  });

  //validation
  const [validEmail, setValidEmail] = useState(false);
  const [validPassword, setValidPassword] = useState(false);

  const [token, setToken] = useState(
    localStorage.getItem("token") ? localStorage.getItem("token") : null
  );

  const navigate = useNavigate();

  useEffect(() => {
    if (token) {
      navigate("/dashboard");
    }
  }, []);

  const onClickSignInButton = () => {
    if (
      authInput.email.trim().length === 0 &&
      authInput.password.trim().length === 0
    ) {
      setValidEmail(true);
      setValidPassword(true);
    }
    if (authInput.email.trim().length === 0) {
      setValidEmail(true);
    } else if (!authInput.email.trim().match(REGEX)) {
      toast.error(t("Please Input Valid Email Address"), {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else if (authInput.password.trim().length === 0) {
      setValidPassword(true);
    } else {
      var urlencoded = new URLSearchParams();
      urlencoded.append("email", authInput.email);
      urlencoded.append("password", authInput.password);
      fetch(`${API_URL}/api/admin/AdminSignup`, {
        method: "POST",
        body: urlencoded,
      })
        .then((res) => {
          return res.json();
        })
        .then((data) => {
          if (data.status === 200) {
            toast.success(data.message, {
              position: "top-right",
              autoClose: 1000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
            navigate("/login");
          } else {
            toast.error(data.message, {
              position: "top-right",
              autoClose: 1000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const onChangeEmailInput = (e) => {
    if (e.target.value.trim().length === 0) {
      setValidEmail(true);
    } else {
      setValidEmail(false);
    }
    setAuthInput({ ...authInput, email: e.target.value });
  };

  const onChangePasswordInput = (e) => {
    if (e.target.value.trim().length === 0) {
      setValidPassword(true);
    } else {
      setValidPassword(false);
    }
    setAuthInput({ ...authInput, password: e.target.value });
  };

  return (
    <Box sx={{ display: "flex", justifyContent: "center" }}>
      <Paper
        elevation={5}
        sx={{
          marginTop: "50px",
          width: "100%",
          maxWidth: "450px",
          height: "50%",
          padding: "20px",
        }}
      >
        <Typography variant="h5" textAlign="center">
          {t('Register')}
        </Typography>
        <Box>
          <TextField
            value={authInput.email}
            onChange={onChangeEmailInput}
            label={t("E-email address")}
            error={validEmail && true}
            sx={{ width: "100%", marginTop: "30px !important" }}
            variant="outlined"
          />
          <Typography variant="body1" color="red">
            {validEmail && "Please input email address"}
          </Typography>
         
            <div style={{ position: "relative" }}>
            <TextField
              value={authInput.password}
              onChange={onChangePasswordInput}
              error={validPassword && true}
              label={t("Password")}
              type={passwordShow ? "text" : "password"}
              sx={{ width: "100%", marginTop: "30px !important" }}
              variant="outlined"
            />
            {passwordShow ? (
              <svg
                onClick={eyeHandler}
                style={{ position: "absolute", top: "50px", right: "18px" }}
                xmlns="http://www.w3.org/2000/svg"
                width="18"
                height="18"
                fill="currentColor"
                class="bi bi-eye-fill"
                viewBox="0 0 16 16"
              >
                <path d="M10.5 8a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0z" />
                <path d="M0 8s3-5.5 8-5.5S16 8 16 8s-3 5.5-8 5.5S0 8 0 8zm8 3.5a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7z" />
              </svg>
            ) : (
              <svg
                onClick={eyeHandler}
                style={{ position: "absolute", top: "50px", right: "18px" }}
                xmlns="http://www.w3.org/2000/svg"
                width="18"
                height="18"
                fill="currentColor"
                class="bi bi-eye-slash-fill"
                viewBox="0 0 16 16"
              >
                <path d="m10.79 12.912-1.614-1.615a3.5 3.5 0 0 1-4.474-4.474l-2.06-2.06C.938 6.278 0 8 0 8s3 5.5 8 5.5a7.029 7.029 0 0 0 2.79-.588zM5.21 3.088A7.028 7.028 0 0 1 8 2.5c5 0 8 5.5 8 5.5s-.939 1.721-2.641 3.238l-2.062-2.062a3.5 3.5 0 0 0-4.474-4.474L5.21 3.089z" />
                <path d="M5.525 7.646a2.5 2.5 0 0 0 2.829 2.829l-2.83-2.829zm4.95.708-2.829-2.83a2.5 2.5 0 0 1 2.829 2.829zm3.171 6-12-12 .708-.708 12 12-.708.708z" />
              </svg>
            )}
          </div>
          {validPassword && (
            <Typography variant="body1" color="red">
              {t('Please input password')}
            </Typography>
          )}
        </Box>
        <Box
          sx={{ display: "flex", justifyContent: "center", margin: "30px 0" }}
        >
          <Button
            variant="contained"
            sx={{ padding: "8px 40px 8px 40px", fontWeight: "700" }}
            onClick={onClickSignInButton}
          >
            {t('create account')}
          </Button>
        </Box>
        <Link to="/login" style={{ textAlign: "center" }}>
          <Typography>{t('Sign in')}</Typography>
        </Link>
      </Paper>
    </Box>
  );
};

export default Register;
