import React from "react";
import AppRoute from "../route/AppRoute";


const AdminLayout = () => {
  return (
    <>
      <AppRoute />
    </>
  );
};

export default AdminLayout;