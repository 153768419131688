import React, { useState } from "react";
import Modal from "antd/lib/modal/Modal";
import { useNavigate } from "react-router-dom";
import { Button, Form, Select } from "antd";
import i18n from "i18next";
import { useTranslation } from "react-i18next";
const { Option } = Select;

const LanguageModel = ({
  visibleLanguageModel,
  onClickLanguageModelCancel,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [language, setLanguage] = useState(["en", "fr", "sw"]);
  const [selectedLanguage, setSelectedLanguage] = useState(null);

  const onClickSubmitBtn = () => {
    localStorage.setItem("language", selectedLanguage);
    i18n.changeLanguage(selectedLanguage);
    onClickLanguageModelCancel();
  };
  const onChangeLanguage = (e) => {
    setSelectedLanguage(e);
  };

  return (
    <Modal
      title={t("Language Model")}
      visible={visibleLanguageModel}
      // onOk={onClickLanguageModelCancel}
      // onCancel={onClickLanguageModelCancel}
      closable={false}
      footer={null}
      width={700}
    >
      <Form labelCol={{ span: 6 }}>
        <Form.Item label={t("Select Language")}>
          <Select
            value={selectedLanguage}
            style={{
              width: "100%",
            }}
            onChange={onChangeLanguage}
          >
            {language.map((item, index) => {
              return (
                <>
                  <Option value={item} key={index}>
                    {item}
                  </Option>
                </>
              );
            })}
          </Select>
        </Form.Item>

        <Form.Item
          // wrapperCol={{ offset: 8, span: 16 }}
          style={{
            display: "flex",
            justifyContent: "end",
            marginBottom: "0",
            marginTop: "20px",
          }}
        >
          {/* <Button type="default" onClick={onClickLanguageModelCancel}>
            {t("Cancel")}
          </Button> */}
          <Button
            type="primary"
            htmlType="submit"
            style={{
              marginLeft: "10px",
            }}
            onClick={onClickSubmitBtn}
          >
            {t('Submit')}
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default LanguageModel;
